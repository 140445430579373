<template>
  <div class="row">
    <div class="col-md-2 mx-auto">
      <half-circle-spinner :animation-duration="1000"
                           :size="27"
                           color="#0097A9" />
    </div>
  </div>
</template>
<script>
import { HalfCircleSpinner } from "epic-spinners"

export default {
  name: "Loader",
  components: {
    HalfCircleSpinner
  }
}
</script>
